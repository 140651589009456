<script setup>
import { userAuthState } from '@/@core/auth'
import AnalyticsDepartmentPSat from '@/@core/components/feedback/AnalyticsDepartmentPSat.vue'
import AnalyticsExperienceBreakdown from '@/@core/components/feedback/AnalyticsExperienceBreakdown.vue'
import AnalyticsFormTypeTracker from '@/@core/components/feedback/AnalyticsFormTypeTracker.vue'
import AnalyticsNPSBreakdown from '@/@core/components/feedback/AnalyticsNPSBreakdown.vue'
import AnalyticsNpsMonthlyTrend from '@/@core/components/feedback/AnalyticsNpsMonthlyTrend.vue'
import AnalyticsSourceTypeTracker from '@/@core/components/feedback/AnalyticsSourceTypeTracker.vue'
import DashboardAnalyticsFeedbackTracker from '@/@core/components/feedback/DashboardAnalyticsFeedbackTracker.vue'
import FormNPSTracker from '@/@core/components/feedback/FormNPSTracker.vue'
import NPSMonthlyAnalysis from '@/@core/components/feedback/NPSMonthlyAnalysis.vue'
import DepartmentWiseRating from '@/@core/components/feedback/DepartmentWiseRating.vue'
import { endOfMonth, startOfMonth } from '@/@core/utils/formatters'
import { can } from '@/@layouts/plugins/casl'
import { cookieRef } from '@/@layouts/stores/config'
import CompareStatisticsCard from '@/views/card-statistics/CompareStatisticsCard.vue'
import CompareStatisticsCardMultiple from '@/views/card-statistics/CompareStatisticsCardMultiple.vue'
import { computed } from 'vue'

definePage({
  meta: {
    name: 'home',
    subject: 'dashboard.ticket',
    action: 'read',
  },  
})


const auth = userAuthState()
const canAccessUnit = can('read', 'masters.unit')

const {
  data: unitsData,
} =  await useApi(createUrl('/masters/units', {
  query: {
    paged: 1,
  },
}), {
  beforeFetch: ({ cancel }) =>{
    if(!canAccessUnit)
    {
      cancel()
    }
  },
})

const units = computed(() => {
  if (unitsData.value?.data) {
    return unitsData.value?.data
  }
  
  return [ {
    id: auth?.user?.unit_id,
    name: auth?.user?.unit_name,
  }]
})
 

const unit_id = ref(cookieRef('selectedUnit') ?? null)

const addDays = (date, days) => {
  const result = new Date(date)

  result.setDate(result.getDate() + days)
  
  return result
}


const addMonths = (date, months) => {
  const result = new Date(date)

  result.setMonth(result.getMonth() + months)
  
  return result
}

const customDateFormat = date => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

const quickDateRangeOptions =[{
  title: 'Today',
  value: `${customDateFormat(new Date())}`,
},
{
  title: 'Yesterday',
  value: `${customDateFormat(addDays(new Date(), -1))}`,
},
{
  title: 'Last 7 days',
  value: `${customDateFormat(addDays(new Date(), -6))} to ${customDateFormat(new Date())}`,
},
{
  title: 'Last 30 days',
  value: `${customDateFormat(addDays(new Date(), -30))} to ${customDateFormat(new Date())}`,
},
{
  title: 'This month',
  value: `${customDateFormat(startOfMonth(new Date()))} to ${customDateFormat(new Date())}`,
},
{
  title: 'Last month',
  value: `${customDateFormat(startOfMonth(addMonths(new Date(), -1)))} to ${customDateFormat(endOfMonth(addMonths(new Date(), -1)))}`,
},
{
  title: 'Custom',
  value: null,
}]


const setQuickDateRange = value => {
 
  dateRange.value = value?.value
}

const quickDateRange = ref('Last 30 days')

const dateRange = ref()

const fromDate = ref(customDateFormat(addDays(new Date(), -30)))
const toDate = ref(customDateFormat(new Date()))

const dateRangeOptions = ref({
  mode: 'range',
  maxDate: new Date(),
  dateFormat: 'Y-m-d',
  enableTime: false,
  enableSeconds: false,
  noCalendar: false,
  altInput: true,
  altFormat: 'F j, Y',
  altInputClass: 'form-control',
  altInputPlaceholder: 'Select date',
  inline: false,
  static: false,
  appendTo: document.body,
  clickOpens: true,
  defaultDate: [new Date(), new Date()],
  onChange: (selectedDates, dateStr, instance) => {
   
    fromDate.value = customDateFormat(selectedDates[0])
    if (selectedDates[1])
      toDate.value = customDateFormat(selectedDates[1])
    else{
      toDate.value = customDateFormat(selectedDates[0])
    }

    dateRange.value = dateStr
  },
  defaultDate: [customDateFormat(addDays(new Date(), -30)), customDateFormat(new Date())],
})

watch( dateRange, value => {
  if (value) {
    quickDateRange.value = quickDateRangeOptions.find(option => option.value === value)?.title || 'Custom'
  }
}, { immediate: true })

const dateRangeValue = computed(() => {
  return [
    fromDate.value,
    toDate.value,
  ]
})

const { data: totalFeedbackData } = useApi(createUrl('/dashboard/feedbacks/total-count', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
    },
  },
}))

const { data: totalFeedbackInviteData } = useApi(createUrl('/dashboard/feedbacks/invitation', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
    },
  },
}))

const { data: totalFeedbackTypeData } = useApi(createUrl('/dashboard/feedbacks/status', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
    },
  },
}))

const { data: totalTicketPendingData } = useApi(createUrl('/dashboard/tickets/status', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
      status: [
        "PENDING",
        "SNOOZE",
        "RE_ASSIGN",
        "RE_OPEN",
        "UNATTENDED",
        "IN_PROGRESS",
      ],
      ticket_type: [
        "COMPLAINT",
      ],
    },
  },
}))

const { data: totalTicketResolvedData } = useApi(createUrl('/dashboard/tickets/status', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
      status: [
        "RESOLVED",
        "DISPOSED",
      ],
      ticket_type: [
        "COMPLAINT",
      ],
    },
  },
}))

const { data: totalTicketTypeData } = useApi(createUrl('/dashboard/tickets/ticket-type', {
  query: {
    search: {
      created_at: dateRangeValue,
      unit_id: unit_id,
    },
  },
}))
</script>

<template>
  <div>
    <VRow>
      <VCol cols="12">
        <span class="text-h3 font-weight-bold text-primary text-capitalize">
          Dashboard
        </span>
      </VCol>
    </VRow>
    <VRow class="mb-4">
      <VCol
        md="12"
        sm="12"
        xs="12"
      >
        <VCard>
          <VCardText>
            <span class="text-h6 font-weight-bold text-primary text-capitalize">
              Filter Dashboard By Date
            </span>
            <VRow align="center">
              <VCol
                cols="12"
                md="4"
                sm="6"
                xs="12"
              >
                <AppSelect
                  v-model="quickDateRange"
                  label="Quick Range"
                  return-object
                  :item-title="item => item.title"
                  :item-value="item => item.title"
                  :items="quickDateRangeOptions"
                  @update:model-value="setQuickDateRange"
                />
              </VCol>
              <VCol
                cols="12"
                md="4"
                sm="6"
                xs="12"
              >
                <AppDateTimePicker
                  v-model="dateRange"
                  label="Range"
                  placeholder="Select date"
                  :config="dateRangeOptions"
                />
              </VCol>

              <VCol
                v-if="units.length > 1"
                cols="12"
                md="4"
                sm="6"
                xs="12"
              >
                <AppSelect
                  v-model="unit_id"
                  clearable
                  multiple
                  label="Select Unit"
                  placeholder="Select Unit"
                  :items="units"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow class="match-height">
      <VCol v-if="can('view', 'dashboard.widget.total_feedbacks')">
        <CompareStatisticsCard
          :sub-title="totalFeedbackData?.difference_date_range"
          :stat="totalFeedbackData?.feedbacks??null"
          :change="totalFeedbackData?.total_feedback_difference"
          :total="totalFeedbackData?.total_feedback_count??null"
          :total-change="totalFeedbackData?.total_feedback_difference"
          title="Total Feedbacks"
          icon="tabler-target-arrow"
        />
      </VCol>
      <VCol v-if="can('view', 'dashboard.widget.feedbacks_by_status')">
        <CompareStatisticsCardMultiple
          :sub-title="totalFeedbackTypeData?.difference_date_range"
          :stat="totalFeedbackTypeData?.feedbacks??null"
          :change="totalFeedbackTypeData?.total_feedback_difference"
          :total="totalFeedbackTypeData?.total_feedback_count??null"
          :total-change="totalFeedbackTypeData?.total_feedback_difference"
          title="Feedbacks By Status"
          icon="tabler-message-2-star"
        />
      </VCol>

      <VCol v-if="can('view', 'dashboard.widget.tickets_by_type')">
        <CompareStatisticsCardMultiple
          :sub-title="totalTicketTypeData?.difference_date_range"
          :stat="totalTicketTypeData?.tickets??null"
          :change="totalTicketTypeData?.difference_percentage"
          :total="totalTicketTypeData?.total_ticket_count??null"
          :total-change="totalTicketTypeData?.total_ticket_difference"
          title="Tickets By Type"
          icon="tabler-activity"
        />
      </VCol>

      <VCol v-if="can('view', 'dashboard.widget.outstanding_complaints')">
        <CompareStatisticsCardMultiple
          :sub-title="totalTicketPendingData?.difference_date_range"
          :stat="totalTicketPendingData?.tickets??null"
          :change="totalTicketPendingData?.difference_percentage"
          :total="totalTicketPendingData?.total_ticket_count??null"
          :total-change="totalTicketPendingData?.total_ticket_difference"

          title="Outstanding Complaints"
          icon="tabler-alert-square-rounded"
        />
      </VCol>

      <VCol v-if="can('view', 'dashboard.widget.resolved_complaints')">
        <CompareStatisticsCardMultiple
          :sub-title="totalTicketResolvedData?.difference_date_range"
          :stat="totalTicketResolvedData?.tickets??null"
          :total="totalTicketResolvedData?.total_ticket_count??null"
          :total-change="totalTicketResolvedData?.total_ticket_difference"

          title="Resolved Complaints"
          icon="tabler-circle-check"
        />
      </VCol>

      <VCol v-if="can('view', 'dashboard.widget.feedback_invitation_sent')">
        <CompareStatisticsCardMultiple
          :sub-title="totalFeedbackInviteData?.difference_date_range"
          :stat="totalFeedbackInviteData?.feedback_invites??null"
          :total="totalFeedbackInviteData?.total_feedback_invite_count??null"
          :total-change="totalFeedbackInviteData?.total_feedback_invite_difference"

          title="Feedback Invitation Sent"
          icon="tabler-send"
        />
      </VCol>
    </VRow>

    <VRow
      v-if="can('view', 'dashboard.widget.department_p_sat')"
      class="match-height"
    >
      <VCol cols="12">
        <AnalyticsDepartmentPSat
          :key="unit_id+fromDate+toDate"
          title="Department PSat"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
    </VRow>
    <VRow class="match-height">
      <VCol
        v-if="can('view', 'dashboard.widget.nps_score')"
        cols="12"
        lg="6"
        md="6"
      >
        <AnalyticsNPSBreakdown
          :key="unit_id+fromDate+toDate"
          title="NPS Score"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.experience_breakdown')"
        cols="12"
        lg="6"
        md="6"
      >
        <AnalyticsExperienceBreakdown
          :key="unit_id+fromDate+toDate"
          title="Experience Breakdown"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>

      <VCol
        v-if="can('view', 'dashboard.widget.department_wise_rating')"
        cols="12"
      >
        <DepartmentWiseRating
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>

      <!-- TODO: Need to add widget permission -->
      <!--
        <VCol
        cols="12"
        lg="6"
        md="6"
        >
        <AnalyticsDepartmentCapaRating
        :key="unit_id+fromDate+toDate"
        title="Experience Breakdown"
        :from="fromDate"
        :to="toDate"
        :unit-id="unit_id"
        />
        </VCol> 
      -->


      <VCol
        v-if="can('view', 'dashboard.widget.department_complaint_analytics')"
        cols="12"
      >
        <AnalyticsDepartment
          :key="unit_id+fromDate+toDate"
          :types="['COMPLAINT']"
          title="Department Complaint Analytics"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
          :colors="[ '#FF0000','#00FF00']"
        />
      </VCol>

      <VCol
        v-if="can('view', 'dashboard.widget.department_compliment_analytics')"
        cols="12"
      >
        <AnalyticsDepartment
          :key="unit_id+fromDate+toDate"
          :types="['COMPLIMENT']"
          title="Department Compliment Analytics"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
          :colors="['#00FF00', '#FF0000']"
        />
      </VCol>


      <VCol
        v-if="can('view', 'dashboard.widget.yearly_nps_trend')"
        cols="12"
        lg="6"
        md="6"
      >
        <AnalyticsNpsMonthlyTrend
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.feedback_tracker')"
        cols="12"
        lg="6"
        md="6"
      >
        <DashboardAnalyticsFeedbackTracker
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.feedback_form_tracker')"
        cols="12"
      >
        <AnalyticsFormTypeTracker
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.form_type_wise_nps_tracker')"
        cols="12"
      >
        <FormNPSTracker
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.nps_monthly_analysis')"
        cols="12"
      >
        <NPSMonthlyAnalysis
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
      <VCol
        v-if="can('view', 'dashboard.widget.feedback_source_tracker')"
        cols="12"
      >
        <AnalyticsSourceTypeTracker
          :key="unit_id+fromDate+toDate"
          :from="fromDate"
          :to="toDate"
          :unit-id="unit_id"
        />
      </VCol>
    </VRow>
  </div>
</template>
